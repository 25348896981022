import React from "react";
import profile_src from "./profile_pic.png";
import resume_src from "./Daniel Park - Resume.pdf";

const Profile = ({ icons, links }) => {
  return (
    <div className="all-center">
      <img
        src={profile_src}
        alt="profile"
        className="profile_pic round-img my-1"
      />
      <span className="name">DANIEL PARK</span>
      <h2>
        My name is <span>Danny</span> and I am <span>Software Engineer</span>.
        <br />I specialize in <span>React</span>, <span>Javascript</span>,{" "}
        <span>SQL</span>, <span>Python</span>, and <span>Baking</span>.
      </h2>
      <div>
        <a
          href={resume_src}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-dark my-1"
        >
          RESUME
        </a>
        <a href={links.contact} className="btn btn-dark my-1">
          CONTACT
        </a>
      </div>
      <div>
        <a href={links.github} target="_blank" rel="noopener noreferrer">
          <i className={icons.github} />
        </a>

        <a href={links.linkedin} target="_blank" rel="noopener noreferrer">
          <i className={icons.linkedin} />
        </a>

        <a href={links.gombakery} target="_blank" rel="noopener noreferrer">
          <i className={icons.gombakery} />
        </a>
      </div>
    </div>
  );
};

Profile.defaultProps = {
  icons: {
    github: "sns fab fa-github fa-3x",
    linkedin: "sns fab fa-linkedin fa-3x",
    gombakery: "sns fas fa-store fa-3x",
  },
  links: {
    github: "https://github.com/dannypark95",
    linkedin: "https://www.linkedin.com/in/daniel-sh-yoon/",
    gombakery: "https://gombakery.com",
    resume: "",
    contact: "mailto: dannypark95@gmail.com",
  },
};

export default Profile;
