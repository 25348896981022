import { useEffect } from "react";

function ResumeRedirect() {
  useEffect(() => {
    window.location.href =
      "https://www.danielsunghyunpark.com/static/media/Daniel%20Park%20-%20Resume.986b245dd6ed16b6fb10.pdf";
  }, []);

  return null; // Render nothing since it's just a redirect.
}

export default ResumeRedirect;
