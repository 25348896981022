import React, { Fragment } from 'react';
import backgroundVideo from './personal_background.mp4';
import backgroundImage from './background_pic.png';

const Background = () => {
  return (
    <Fragment>
      <video autoPlay muted loop className='bg-video'>
        <source src={backgroundVideo} type='video/mp4' />
      </video>
      <img src={backgroundImage} alt='mobile_pic' className='mobile-img' />
    </Fragment>
  );
};

export default Background;
