import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Background from './components/background/Background';
import Profile from './components/profile/Profile';
import ResumeRedirect from './components/resumeRedirect/ResumeRedirect';
import './App.css';

function App() {
  return (
    <Router>
      <Background />
      <Routes>
        <Route path='/' element={<Profile />} />
        <Route path='/resume' element={<ResumeRedirect />} />
      </Routes>
    </Router>
  );
}

export default App;
